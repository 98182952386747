import React, { useEffect, useRef } from 'react';
import { TvGuideItem } from '@src/interfaces';
import useOasisStickyPlayer from '@src/utils/hooks/useOasisStickyPlayer';
import classNames from 'classnames';
import dayjs from 'dayjs';
import SvgImage from '@src/components/SvgImage';
import Link from 'next/link';
import styles from './styles.module.scss';

const TvTimelineItem = ({ title, description, startTime, endTime, now, index }: TvGuideItem & {index: number}) => {

  const progressLineRef = useRef<HTMLDivElement>(null);
  const { onOasisTrigger } = useOasisStickyPlayer();

  useEffect(() => {
    if (now === true && typeof startTime !== "undefined" && typeof endTime !== "undefined") {

      // calculate the percentage
      const range = endTime - startTime;
      const correctedStartValue = Math.floor(Date.now() / 1000) - startTime;
      const percentage = (correctedStartValue * 100) / range;

      const element = progressLineRef.current;
      if (element) {
        element.style.width = `${percentage}%`;
      }
    }
  }, [startTime, endTime, now]);

  return (
    <div className={classNames(styles.tvProgramCardItem, now ? styles.runningnow : false, `tvProgramCardItem--${index}`)} title={description}>
      <div className={styles.text}>
        <div className={styles.type}>
          { now && 'Jetzt' }
          { index === 1 && 'Gleich danach' }
          { index === 2 && '\u00A0' }
          { index === 3 && '\u00A0' }
        </div>
        { startTime && (
          <span className={styles.time}>{dayjs.unix(startTime).format('HH:mm')}</span>
        )}

        <p className={classNames(styles.title, { [styles.titleLong]: !now })}>{now && <SvgImage reference="live"/>}{title}</p>

        { now && (
          <div className={styles.progressLine}>
            <div ref={progressLineRef} className={styles.progress} />
          </div>
        )}

        { now && (
          <button className={styles.playLive} onClick={onOasisTrigger} type="button">
            <SvgImage reference="play-circle" title="Video" />
          </button>
        )}

        { !now && (
          <Link as="/tv-programm" href="/">
            <a className={styles.chevron} title="TV Programm PULS 24">
              <SvgImage ariaHidden reference="chevron-thin-right" />
            </a>
          </Link>
        )}
      </div>
    </div>
  );
};
export default TvTimelineItem;
