import React from 'react';
import ComponentTitle from '@src/components/ComponentTitle';
import { TvGuideItem } from '@src/interfaces';
import TvTimelineItem from '../TvTimelineItem';
import styles from './styles.module.scss';

const TvTimeline = ({title, items}: {title?: string, items: TvGuideItem[]}) => (
  <>
    <ComponentTitle title={title} />
    <div className={styles.tvTimeline}>
      {items?.map((item: TvGuideItem, index) => (
        <TvTimelineItem key={index} index={index} {...item} />
    ))}
    </div>
  </>
);
export default TvTimeline;
